import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import {
  type DtoUpdateGamePackRequest,
  EnumsGamePackChangeLevel,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { type Repository, useArrayState } from '../../../hooks/useArrayState';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  type GameGridSettings,
  MinigameContextProvider,
  useMinigameContext,
} from '../../../pages/Minigame/Context';
import { GamePicker } from '../../../pages/Minigame/Picker';
import {
  apiService,
  type UpdateGameRequest,
} from '../../../services/api-service';
import { NotificationType, RoleUtils } from '../../../types';
import {
  type Game,
  type GameLikeEditingContext,
  type GamePack,
  GamePackActionType,
} from '../../../types/game';
import {
  fromDTOGamePack,
  toGamePackPromotionalAssetsDTO,
} from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { canMove } from '../../../utils/dnd';
import { BannerWarningMessages } from '../../common/BannerWarningMessage';
import { CollapsibleSection } from '../../common/CollapsibleSection';
import { HeaderLayout, SearchBarV2 } from '../../Header';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { LPLogo } from '../../icons/LPLogo';
import { MenuIcon } from '../../icons/MenuIcon';
import { Loading } from '../../Loading';
import { useNotificationDataSource } from '../../Notification/Context';
import { TrainingProfilePicker } from '../../TrainingProfile/TrainingProfilePicker';
import { useUser } from '../../UserContext';
import {
  DeleteNonPrimeGameLikeModal,
  DeletePrimeGameLikeModal,
  DuplicatePrimeGameLikeModal,
  MiniGameCard,
  PrimeWarningMessage,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
} from '../GameCenter';
import { useGameEditorStore } from '../GameEditorStore';
import { useLocalGamePlayStore } from '../GamePlayStore';
import { GamePackChangeConfirm } from './GamePackChangeConfirm';
import {
  CategoriesField,
  CoverField,
  DescriptionField,
  Featured as FeaturedField,
  LobbyBackgroundField,
  PackNameField,
  PlayerRangeEditor,
  PromotionalAssets,
  ReplayableField,
  TeamRandomization,
  UGCPromptTemplateEditor,
  useTriggerCancelConfirmModal,
} from './Shared';
import {
  type GamePackEditorControlledProps,
  type GamePackEditorFormData,
} from './types';
import { GamePackEditorUtils } from './utils';

function Header(props: {
  onCancel: () => void;
  onSubmit: (confirm?: {
    level: EnumsGamePackChangeLevel;
    note?: string;
  }) => void;
  disabledReason: 'loading' | 'processing' | 'errors' | null;
  actionElement: string;
  needConfirm?: boolean;
}): JSX.Element {
  const isAdmin = RoleUtils.isAdmin(useUser());
  const ctx = useMinigameContext();

  const [query, setQuery] = useState<string | null>(null);
  const handleSearchChanged = (q: string) => {
    if (!ctx.embed) return;
    const trimmed = q.trim();
    setQuery(trimmed);
    const params = new URLSearchParams(ctx.embedCtx?.search);
    params.set('q', trimmed);
    ctx.updateEmbedCtx({ search: params.toString() });
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setOpenConfirm(false));

  return (
    <HeaderLayout
      bgClassName={`${isAdmin ? 'bg-admin-red' : ''}`}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type={isAdmin ? 'admin' : 'default'} />
          <div className={`text-xl text-white flex flex-row font-medium`}>
            Game Pack Editor
          </div>
        </div>
      }
      right={
        <>
          <SearchBarV2
            defaultValue={query}
            onChange={handleSearchChanged}
            bgClassName='bg-black'
          />
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <div ref={ref} className='relative'>
            <button
              className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
              type='button'
              disabled={!!props.disabledReason}
              onClick={() => {
                if (props.needConfirm) {
                  setOpenConfirm(true);
                } else {
                  props.onSubmit();
                }
              }}
            >
              {props.disabledReason === 'loading' && (
                <Loading
                  text=''
                  containerClassName='mr-2'
                  imgClassName='w-5 h-5'
                />
              )}
              {props.actionElement}
            </button>
            {openConfirm && (
              <div className='absolute right-0 mt-1'>
                <GamePackChangeConfirm
                  onClick={(level, note) => {
                    props.onSubmit({ level, note });
                  }}
                />
              </div>
            )}
          </div>
        </>
      }
    />
  );
}

interface DragGameEntry {
  id: string;
  index: number;
}

function GameEntry(props: {
  game: Game;
  index: number;
  onGameClick: (game: Game) => void;
  onMove: (from: number, to: number) => void;
  onDelete: (game: Game) => void;
}): JSX.Element {
  const { game, index, onMove, onDelete } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'minigame',
    hover(item: DragGameEntry, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'minigame',
    item: () => {
      return { id: game.id, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));

  const handleDelete = () => {
    onDelete(game);
  };
  return (
    <div
      className={`flex items-center gap-2.5 w-full ${collected.opacity}`}
      ref={ref}
    >
      <button
        type='button'
        ref={drag}
        className='btn cursor-move flex-shrink-0'
      >
        <MenuIcon />
      </button>
      <div className='flex-grow'>
        <MiniGameCard
          game={game}
          myBadge
          disableAction
          onGameClick={props.onGameClick}
        />
      </div>
      <button
        type='button'
        className='btn flex w-7.5 h-7.5 flex-shrink-0 border border-secondary rounded-lg items-center justify-center text-red-002'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

type GamePackInfoProps = {
  pack: GamePack | null | undefined;
  games: Game[];
  setGames: (games: Game[]) => void;
  gameDao: Repository<Game>;
};

function GamePackMiniGameList(props: GamePackInfoProps): JSX.Element {
  const { pack, games, setGames, gameDao } = props;

  const { data, error, isLoading, isValidating } = useSWRImmutable(
    pack?.id ? [`/gampack/mini-game-list/${pack.id}`, pack.id] : null,
    async ([, id]) => {
      const resp = await apiService.gamePack.getLinkedGames(id);
      return resp.data.games;
    },
    { shouldRetryOnError: false, revalidateOnMount: true }
  );

  useEffect(() => {
    if (data) setGames(data);
  }, [data, setGames]);

  const handleMove = (from: number, to: number): void => {
    const updated = [...games];
    const game = updated[from];
    updated.splice(from, 1);
    updated.splice(to, 0, game);
    setGames(updated);
  };

  const store = useGameEditorStore();
  const [, setCreatingGame] = useGameLikeWorkspace('game', 'create');
  const emitter = useGameLikeEventEmitter('game');
  const aborter = useRef<AbortController>();
  if (!aborter.current) aborter.current = new AbortController();
  const signal = aborter.current.signal;
  const appendGame = useLiveCallback(async (game: Game) => {
    // Auto publish minigames if they are being attached to a prime game, and
    // ensure the dao is updated.
    if (pack?.isPrime) {
      const resp = await apiService.game.publish(game.id, {});
      const primeGame = resp.data.game;
      setGames([...games, primeGame]);
    } else {
      setGames([...games, game]);
    }
  });

  useEffect(() => {
    emitter.on('created', appendGame, { signal });
    // In case the minigame details are edited, ensure the list is up to date!
    emitter.on('updated', (game) => gameDao.updateItem(game), { signal });
  }, [appendGame, emitter, gameDao, signal]);

  useEffect(() => {
    return () => aborter.current?.abort();
  }, [aborter]);

  return (
    <div
      className={`w-full h-full flex flex-col items-center flex-shrink-0 gap-4`}
    >
      {isValidating ? (
        <Loading text='' containerClassName='w-full' />
      ) : (
        <DndProvider backend={HTML5Backend}>
          {games.length > 0 && (
            <div className='flex flex-col w-full'>
              {games.map((g, i) => (
                <GameEntry
                  key={g.id}
                  game={g}
                  index={i}
                  onMove={handleMove}
                  onDelete={gameDao.deleteItem}
                  onGameClick={async (game) => {
                    // Make the store aware of the minigame and load blocks so...
                    // await store.registerMiniGameWithStoreFetchBlocks(game);
                    // ... there is a game to show the block / minigame editor.
                    await store.setEditingGame(game, { fetchBlocks: true });
                  }}
                />
              ))}
            </div>
          )}
        </DndProvider>
      )}
      <button
        className='w-full h-9 btn-secondary flex items-center justify-center'
        type='button'
        onPointerUp={() => {
          setCreatingGame({ openEditorAfterCreated: false });
        }}
      >
        Add Blank Minigame
      </button>
      {(!error && !isLoading && games.length === 0) ||
        (!pack?.id && games.length === 0 && (
          <div className='px-8 text-secondary font-normal text-base text-center'>
            Add a blank mini game or choose a Minigame to add from the right
            panel
          </div>
        ))}
      {error && (
        <div className='text-red-002 test-xl text-center mb-40'>
          {error.toString()}
        </div>
      )}
    </div>
  );
}

type LocalStateWithFormData = {
  coverMediaId: string | undefined;
  tags: string[] | null;
  linkedGames: Game[];
  data: GamePackEditorFormData;
};

type UpdateWithFormData = {
  id?: string;
} & LocalStateWithFormData;

type PublishWithFormData = {
  id: string;
} & LocalStateWithFormData;

// The existing "publish" API requires various fields to be sent, even though
// it's not in the types. If these fields are not sent (the backend perceives
// them as nil) and they are technically optional (pointers) in the update
// struct, the `nil` will be considered purposeful and will overwrite the
// corresponding field of the published copy: aka description, cover media,
// tags, etc will be empty in the published copy of the gamepack. So we have to
// send all the data, every time, to be safe.
function intoUpdateGamePackRequest(
  incoming: LocalStateWithFormData
): Omit<DtoUpdateGamePackRequest, 'changeLevel' | 'changeNote'> {
  const { coverMediaId, tags, data } = incoming;

  const payload: Omit<DtoUpdateGamePackRequest, 'changeLevel' | 'changeNote'> =
    {
      name: data.name,
      description: data.description ?? '',
      coverMediaId: coverMediaId ?? '',
      tags: tags ?? undefined,
      featured: data.featured ?? undefined,
      playerRange: data.playerRange ?? undefined,
      replayable: data.replayable ?? undefined,
      promotionalAssets: toGamePackPromotionalAssetsDTO(data.promotionalAssets),
      teamRandomizationSettings: data.teamRandomizationSettings ?? undefined,
      marketingSettings: data.marketingSettings ?? undefined,
      ugcSettings: data.ugcSettings ?? undefined,
    };

  return payload;
}

function intoUpdateGameRequest(incoming: Game): UpdateGameRequest {
  const payload: UpdateGameRequest = {
    name: incoming.name,
    description: incoming.description ?? '',
    coverMediaId: incoming.cover?.id,
    tags: incoming.tags?.map((t) => t.name),
  };

  return payload;
}

async function writeGamePackPublish(
  _url: string,
  options: {
    arg: PublishWithFormData;
  }
) {
  const { id, linkedGames, data } = options.arg;
  const payload = intoUpdateGamePackRequest(options.arg);

  const gameIds: string[] = [];

  for (const game of linkedGames) {
    if (game.isPrime) {
      gameIds.push(game.id);
    } else {
      const resp = await apiService.game.publish(
        game.id,
        intoUpdateGameRequest(game)
      );
      gameIds.push(resp.data.game.id);
    }
  }

  let assessmentSettings = undefined;
  if (data.assessmentSettings?.gameId && gameIds.length > 0) {
    // we have an assessment defined, so we need to map it to the nwe game id.
    assessmentSettings = {
      gameId: gameIds[gameIds.length - 1],
    };
  }

  const published = await apiService.gamePack.publish(id, {
    ...payload,
    childrenIds: gameIds,
    assessmentSettings,
  });
  return published.data.gamePack;
}

async function writeGamePackUpdate(
  _url: string,
  options: {
    arg: {
      data: UpdateWithFormData;
      isPrime: boolean;
      confirm?: { level: EnumsGamePackChangeLevel; note?: string };
    };
  }
) {
  const payload = intoUpdateGamePackRequest(options.arg.data);

  let packId = options.arg.data.id;

  const childrenIds = options.arg.data.linkedGames.map((g) => g.id);
  if (!packId) {
    const res = await apiService.gamePack.create({
      ...payload,
      version: EnumsGamePackVersion.GamePackVersionV1,
      childrenIds,
    });
    packId = res.data.gamePack.id;
    return { created: true, pack: fromDTOGamePack(res.data.gamePack) };
  } else {
    if (options.arg.isPrime && !options.arg.confirm) {
      throw new Error('Confirmation is required when updating a game pack');
    }
    const res = await apiService.gamePack.update(packId, {
      ...payload,
      changeLevel:
        options.arg.confirm?.level ??
        EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      changeNote: options.arg.confirm?.note,
      childrenIds,
    });
    return { created: false, pack: fromDTOGamePack(res.data.gamePack) };
  }
}

export function GamePackEditorWithWorkspaceAdapter(props: {
  onClose?: () => void;
}) {
  const store = useLocalGamePlayStore();
  const emitter = useGameLikeEventEmitter('gamePack');
  const [creating, setCreating] = useGameLikeWorkspace('gamePack', 'create');
  const [editing, setEditing] = useGameLikeWorkspace('gamePack', 'edit');
  const [publishing, setPublishing] = useGameLikeWorkspace(
    'gamePack',
    'publish'
  );

  useEffect(() => {
    // NOTE(drew): I believe this merges in changes from elsewhere in the app
    // with what is present in the editor? Unclear under what circumstances we
    // would actually need this.
    return emitter.on('updated', (pack: Partial<GamePack>) => {
      if (!editing) return;
      setEditing({ ...editing, ...pack });
    });
  }, [emitter, editing, setEditing]);

  const onCloseEditor = async (pack: GamePack | null | undefined) => {
    // sync updated game pack to loaded store
    const loadedGamePack = store.getLoadedGamePack();
    if (loadedGamePack && loadedGamePack.id === pack?.id && pack) {
      await store.load({ pack });
    }

    setCreating(false);
    setEditing(null);
    setPublishing(null);
    props.onClose?.();
  };

  const pack = publishing ?? editing;
  const contextLabel = creating ? 'create' : publishing ? 'publish' : 'edit';

  return (
    <GamePackEditor
      onClose={onCloseEditor}
      pack={pack}
      context={contextLabel}
    />
  );
}

function DetailSettingsEditor(
  props: GamePackEditorControlledProps & {
    pack: Nullable<GamePack>;
    setIsUploading: (val: boolean) => void;
    onShowOverlay: (overlay: JSX.Element | null) => void;
  }
) {
  const cover = props.pack?.cover ?? null;
  const featureable = !!props.pack?.id && !!props.pack.isPrime;

  return (
    <div className='w-full flex flex-col gap-4'>
      <div className='w-full flex flex-col gap-3'>
        <ReplayableField {...props} />
        {featureable && <FeaturedField {...props} cover={cover} />}
        {featureable && !!props.pack && (
          <PromotionalAssets {...props} cover={cover} pack={props.pack} />
        )}
      </div>
      <CategoriesField {...props} tags={props.pack?.tags} />
      <DescriptionField {...props} />
    </div>
  );
}

function TeamSettingsEditor(props: GamePackEditorControlledProps) {
  return (
    <div className='w-full flex flex-col gap-4'>
      <PlayerRangeEditor control={props.control} />
      <TeamRandomization {...props} icebreakerEnabled className='mt-7.5' />
    </div>
  );
}

function GamePackMarketingSettingsEditor(props: GamePackEditorControlledProps) {
  return (
    <div className='flex flex-col gap-4'>
      <LobbyBackgroundField {...props} />
    </div>
  );
}

export const GamePackEditor = (props: {
  onClose: (pack?: GamePack | null) => Promise<void>;
  pack: GamePack | null | undefined;
  context: GameLikeEditingContext;
}) => {
  const { pack, context } = props;

  const gridSettings = useInstance<GameGridSettings>(() => ({
    rows: 4,
    cols: 2,
  }));

  const ref = useRef<HTMLDivElement | null>(null);
  const [cover, setCover] = useState<Media | null>(pack?.cover || null);

  const notificationDataSource = useNotificationDataSource();
  const emitter = useGameLikeEventEmitter('gamePack');
  const [games, setGames, gameDao] = useArrayState<Game>({
    compare: (a, b) => a.id === b.id,
  });

  const gameIds = useMemo(() => games.map((g) => g.id), [games]);

  const picked = useCallback(
    (gameId: string) => {
      return gameIds.includes(gameId);
    },
    [gameIds]
  );

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const form = useForm<GamePackEditorFormData>({
    defaultValues: GamePackEditorUtils.DefaultGamePackFormValues(pack),
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = form;
  const [overlay, setOverlay] = useState<JSX.Element | null>(null);

  const {
    trigger,
    error: errorOnSave,
    isMutating,
    reset: resetSave,
  } = useSWRMutation(`/gamepacks/${pack?.id}/update`, writeGamePackUpdate);

  const {
    trigger: triggerPublish,
    error: errorOnPublish,
    isMutating: isMutatingPublish,
    reset: resetPublish,
  } = useSWRMutation(`/gamepacks/${pack?.id}publish`, writeGamePackPublish);

  const errors: Error[] = [];
  if (errorOnSave) errors.push(errorOnSave);
  if (errorOnPublish) errors.push(errorOnPublish);

  const resetErrors = () => {
    resetSave();
    resetPublish();
  };

  const disabledReason = errors.length
    ? 'errors'
    : isMutating || isMutatingPublish
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  const onSubmit = (confirm?: {
    level: EnumsGamePackChangeLevel;
    note?: string;
  }) => {
    handleSubmit(async (data: GamePackEditorFormData) => {
      const updated = await trigger({
        data: {
          id: pack?.id,
          coverMediaId: cover?.id,
          linkedGames: games,
          tags: data.tags ?? [],
          data,
        },
        isPrime: pack?.isPrime ?? false,
        confirm,
      });

      if (!updated || !updated.pack) {
        return;
      }

      if (updated.created) {
        emitter.emit('created', updated.pack);
      }

      emitter.emit('updated', {
        ...updated.pack,
        childrenIds: gameIds,
      });

      if (context === 'publish') {
        const published = await triggerPublish({
          id: updated.pack.id,
          coverMediaId: cover?.id,
          linkedGames: games,
          tags: data.tags ?? [],
          data,
        });
        if (published) {
          emitter.emit('published', published);
          notificationDataSource.send({
            id: uuidv4(),
            toUserClientId: '',
            type: NotificationType.GamePackAction,
            createdAt: Date.now(),
            metadata: {
              actionType: GamePackActionType.PublishGamePack,
              gamePack: published,
            },
          });
          await props.onClose(published);
        }
      } else {
        await props.onClose(updated.pack);
      }
    })();
  };

  const confirmCancel = useTriggerCancelConfirmModal();
  const onCancel = async () => {
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    props.onClose();
  };

  return (
    <MinigameContextProvider
      embed
      handleAdd={gameDao.addItem}
      handleRemove={gameDao.deleteItem}
      picked={picked}
      gridSettings={gridSettings}
    >
      <FormProvider {...form}>
        <form className='w-full h-full bg-black fixed flex flex-col z-50 top-0'>
          <Header
            disabledReason={
              disabledReason ||
              (isUploading ? 'processing' : null) ||
              (errors.length > 0 ? 'errors' : null)
            }
            needConfirm={context === 'edit' && pack?.isPrime}
            actionElement={context === 'publish' ? 'Public' : 'Save'}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
          <PrimeWarningMessage gameLike={pack} />
          <BannerWarningMessages errors={errors} reset={resetErrors} />
          <div className='flex-grow min-h-0 flex flex-row w-full'>
            <div
              ref={ref}
              className={`w-1/3 relative overflow-y-auto scrollbar text-white px-7`}
            >
              <div className={`w-full flex flex-col items-center`}>
                <div className='w-full flex flex-col gap-6 my-7.5'>
                  <PackNameField
                    control={control}
                    watch={watch}
                    register={register}
                  />

                  <CoverField
                    control={control}
                    watch={watch}
                    register={register}
                    cover={cover}
                    setIsUploading={setIsUploading}
                    setCover={setCover}
                  />

                  <div className='font-bold'>
                    <div className='mb-2'>Minigames</div>
                    <GamePackMiniGameList
                      pack={pack}
                      games={games}
                      setGames={setGames}
                      gameDao={gameDao}
                    />
                  </div>

                  <hr className='w-full border-secondary' />

                  <CollapsibleSection
                    title='Game Details'
                    defaultOpened={false}
                  >
                    <DetailSettingsEditor
                      pack={pack}
                      control={control}
                      register={register}
                      watch={watch}
                      setIsUploading={setIsUploading}
                      onShowOverlay={setOverlay}
                    />
                  </CollapsibleSection>

                  <CollapsibleSection
                    title='Team Settings'
                    defaultOpened={false}
                  >
                    <TeamSettingsEditor
                      control={control}
                      register={register}
                      watch={watch}
                    />
                  </CollapsibleSection>

                  <CollapsibleSection
                    title='Marketing Meta Data'
                    defaultOpened={false}
                  >
                    <GamePackMarketingSettingsEditor
                      control={control}
                      register={register}
                      watch={watch}
                    />
                  </CollapsibleSection>

                  <CollapsibleSection
                    title='Custom Training Settings'
                    defaultOpened={false}
                  >
                    <Controller<
                      GamePackEditorFormData,
                      'ugcSettings.promptTemplateId'
                    >
                      name='ugcSettings.promptTemplateId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <UGCPromptTemplateEditor
                            label='Select an AI Prompt Template'
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                    <Controller<
                      GamePackEditorFormData,
                      'ugcSettings.trainingPromptProfileId'
                    >
                      name='ugcSettings.trainingPromptProfileId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <TrainingPromptProfileEditor
                            label='Select a Training Profile'
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                    <Controller<
                      GamePackEditorFormData,
                      'ugcSettings.trainingEditorChatProfileId'
                    >
                      name='ugcSettings.trainingEditorChatProfileId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <TrainingPromptProfileEditor
                            label='Select a Training Editor Chat Profile'
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                  </CollapsibleSection>
                </div>
              </div>
            </div>
            <div className='flex-1 h-full overflow-auto scrollbar'>
              <GamePicker primeOnly={pack?.isPrime} />
            </div>
          </div>
        </form>
      </FormProvider>
      {overlay}
    </MinigameContextProvider>
  );
};

export const DeleteGamePackModal = (): JSX.Element | null => {
  const emitter = useGameLikeEventEmitter('gamePack');
  const [deletingGamePack, setDeletingGamePack] = useGameLikeWorkspace(
    'gamePack',
    'delete'
  );
  const [activeGamePack, setActiveGamePack] = useGameLikeWorkspace(
    'gamePack',
    'active'
  );
  const { state, error, call } = useAsyncCall(
    useCallback(async (packId: string) => {
      return await apiService.gamePack.delete(packId);
    }, [])
  );

  const onCancel = () => {
    setDeletingGamePack(null);
  };

  const onDelete = async () => {
    if (!deletingGamePack) return;
    const resp = await call(deletingGamePack.id);
    if (!resp) return;
    emitter.emit('deleted', deletingGamePack);
    if (activeGamePack?.id === deletingGamePack.id) {
      setActiveGamePack(null);
    }
    setDeletingGamePack(null);
  };

  if (!deletingGamePack) {
    return null;
  }

  if (deletingGamePack.isPrime) {
    return (
      <DeletePrimeGameLikeModal
        type={deletingGamePack.type}
        state={state.transformed}
        error={error}
        onCancel={onCancel}
        onConfirm={onDelete}
      />
    );
  }

  return (
    <DeleteNonPrimeGameLikeModal
      type={deletingGamePack.type}
      state={state.transformed}
      error={error}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  );
};

export const DuplicateGamePackModal = (): JSX.Element | null => {
  const emitter = useGameLikeEventEmitter('gamePack');
  const [duplicatingGamePack, setDuplicatingGamePack] = useGameLikeWorkspace(
    'gamePack',
    'duplicate'
  );

  const onCancel = () => {
    setDuplicatingGamePack(null);
  };
  const { state, error, call } = useAsyncCall(
    useCallback(async (packId: string) => {
      return (await apiService.gamePack.duplicate(packId, { fork: false })).data
        .gamePack;
    }, [])
  );

  const onDuplicate = async () => {
    if (!duplicatingGamePack) return;
    const pack = await call(duplicatingGamePack.id);
    if (!pack) return;
    emitter.emit('duplicated', pack);
    setDuplicatingGamePack(null);
  };

  if (!duplicatingGamePack) {
    return null;
  }

  return (
    <DuplicatePrimeGameLikeModal
      type={duplicatingGamePack.type}
      state={state.transformed}
      error={error}
      onCancel={onCancel}
      onConfirm={onDuplicate}
    />
  );
};

export function TrainingPromptProfileEditor(props: {
  label: string;
  value?: string | null;
  onChange: (value: string | null | undefined) => void;
}) {
  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full'>
        <div className='mb-2 w-full flex items-center justify-between'>
          <div className='font-bold'>{props.label}</div>
        </div>
        <TrainingProfilePicker
          profile={props.value}
          onChange={(t) => {
            props.onChange(t.id);
          }}
        />
      </div>
    </div>
  );
}
